var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": _vm.closeOnOverlayClick,
      "with-button-close": _vm.withButtonClose
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "size": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/illustration-9-raw.png'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto",
            "mb": "8px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "mb": "16px",
            "color": "primary.400"
          }
        }, [_vm._v(" Keluar halaman ini? ")]), _c('c-text', {
          attrs: {
            "font-weight": "400",
            "font-size": ['14px', '16px'],
            "color": "#555555"
          }
        }, [_vm._v(" Hanya beberapa langkah lagi untuk menjadi klien Dietela. "), _c('br'), _vm._v(" Jika keluar dari halaman ini akan membatalkan pesananmu ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "px": "24px",
            "mb": "32px",
            "display": "flex",
            "flex-direction": "column",
            "gap": "8px",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.handleContinuePayment
          }
        }, [_vm._v(" Lanjutkan Bayar ")]), _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-loading": _vm.isLoadingExitPage
          },
          on: {
            "click": _vm.handleExitPage
          }
        }, [_vm._v(" Keluar Halaman ")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }