var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": _vm.closeOnOverlayClick,
      "with-button-close": _vm.withButtonClose
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "size": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/illustration-130323-raw.jpeg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center"
          }
        }, [_c('c-text', {
          attrs: {
            "font-weight": "700",
            "font-size": ['18px', '20px'],
            "mb": "16px"
          }
        }, [_vm._v(" Program Tidak Dapat Dibeli ")]), _c('c-text', {
          attrs: {
            "font-weight": "400",
            "font-size": ['14px', '16px'],
            "color": "#555555"
          }
        }, [_vm._v(" Kamu masih memiliki program aktif lainnya. Silahkan menunggu hingga program selesai atau lakukan pembelian dengan jenis program yang sama. ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "px": "24px",
            "mb": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" Kembali ")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }