var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.choosenComponent ? _c(_vm.choosenComponent, _vm._g(_vm._b({
    tag: "component"
  }, 'component', _vm.$attrs, false), _vm.$listeners)) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }