<template>
  <BaseModal
    :is-open="isOpen"
    :close-on-overlay-click="closeOnOverlayClick"
    :with-button-close="withButtonClose"
    @close="close"
  >
    <template #header>
      <c-box
        display="flex"
        justify-content="center"
        margin="32px 0 0 0"
      >
        <CImage
          height="150px"
          object-fit="cover"
          :src="image"
          alt="Image"
        />
      </c-box>
    </template>
    <template #body>
      <c-box
        display="flex"
        flex-direction="column"
        align-items="center"
        px="24px"
        py="16px"
        text-align="center"
      >
        <c-text
          font-weight="500"
          :font-size="['16px','18px']"
          :mb="['0', '16px']"
        >
          {{ text }}
        </c-text>
        <!-- <c-text
          fontWeight="400"
          :fontSize="['14px', '16px']"
          color="#555555"
        >
          {{ title }}
        </c-text> -->
      </c-box>
    </template>
    <template #footer>
      <c-box
        width="100%"
        px="42px"
        mb="24px"
      >
        <BaseButton
          color="primary"
          border-radius="50px"
          width="100%"
          h="48px"
          @click="submit"
        >
          {{ buttonText }}
        </BaseButton>
      </c-box>
    </template>
  </BaseModal>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'

export default {
  name: 'ModalNotification',
  components: { BaseModal, BaseButton },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    image: {
      type: String,
      default: '',
      required: true,
    },
    text: {
      type: String,
      default: '',
      required: true,
    },
    buttonText: {
      type: String,
      default: 'Kembali',
    },
    buttonLink: {
      type: String,
      default: '',
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: true,
    },
    withButtonClose: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    submit() {
      if (this.buttonLink) {
        this.$router.push(this.buttonLink).catch(() => {return null})
      }
      this.close()
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
