<template>
  <BaseModal
    :is-open="isOpen"
    :close-on-overlay-click="closeOnOverlayClick"
    :with-button-close="withButtonClose"
    @close="close"
  >
    <template #header>
      <c-box
        display="flex"
        justify-content="center"
        margin="24px 0 0 0"
      >
        <CImage
          size="150px"
          object-fit="cover"
          :src="require('@/assets/images/illustration-9-raw.png')"
          alt="Image Confirmation"
        />
      </c-box>
    </template>
    <template #body>
      <c-box
        display="flex"
        flex-direction="column"
        align-items="center"
        px="24px"
        py="16px"
        text-align="center"
        max-width="500px"
        mx="auto"
        mb="8px"
      >
        <BaseText
          size-mobile="16px"
          size-desktop="20px"
          mb="16px"
          color="primary.400"
        >
          Keluar halaman ini?
        </BaseText>
        <c-text
          font-weight="400"
          :font-size="['14px', '16px']"
          color="#555555"
        >
          Hanya beberapa langkah lagi untuk menjadi klien Dietela.
          <br>
          Jika keluar dari halaman ini akan membatalkan pesananmu
        </c-text>
      </c-box>
    </template>
    <template #footer>
      <c-box
        width="100%"
        px="24px"
        mb="32px"
        display="flex"
        flex-direction="column"
        gap="8px"
        max-width="500px"
        mx="auto"
      >
        <BaseButton
          color="primary"
          rounded="1000px"
          width="100%"
          @click="handleContinuePayment"
        >
          Lanjutkan Bayar
        </BaseButton>

        <BaseButton
          color="primary"
          rounded="1000px"
          width="100%"
          variant="outlined"
          :is-loading="isLoadingExitPage"
          @click="handleExitPage"
        >
          Keluar Halaman
        </BaseButton>
      </c-box>
    </template>
  </BaseModal>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'ModalConfirmExitTransactionPage',
  components: { BaseText, BaseModal, BaseButton },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: true,
    },
    withButtonClose: {
      type: Boolean,
      default: true,
    },
    isLoadingExitPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    handleContinuePayment() {
      this.close()
    },
    handleExitPage() {
      this.$emit('handle-exit-page')
    },
  },
}
</script>
